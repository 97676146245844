





































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { IBuyTicket, ITickets, PayloadState } from '@/types/types';
const contactModule = namespace('contact');
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { numeric, required, min, max } from 'vee-validate/dist/rules';
import EmptyPage from '@/components/pages/EmptyPage.vue';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid phone number provided',
});
extend('min', {
  ...min,
  message: `Invalid phone number provided`,
});
extend('max', {
  ...max,
  message: `Maximum phone number must be 13`,
});

@Component({
  name: 'CreateEventDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
    EmptyPage,
  },
})
export default class BuyTicketBottomSheet extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @contactModule.Getter('getResetFormValues') resetFormValues!: IBuyTicket;

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;
  @Prop({ default: null }) details!: ITickets;

  dateOfBirth = '';
  menu = false;

  ticket: IBuyTicket = {
    eventId: '',
    ticketId: '',
    amountPaid: 0,
    status: '',
  };

  @Watch('resetFormState')
  onFormChange(payload: boolean): void {
    if (payload) {
      this.ticket = { ...this.resetFormValues };
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Emit('ticket')
  addTicket(): any {
    const payload = {
      ticketId: this.details?._id,
      amountPaid:
        this.details?.discountedPrice > 0
          ? this.details?.discount
          : this.details?.price,
      status: 'ACTIVE',
    };
    return payload;
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'add',
      state: false,
    };
  }
}
